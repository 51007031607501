import '../../styles/Footer.css';
import Code_image from '../../assets/qr-code-blanc.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { Condition_vente } from '../../condition_vente';

function footer() {
    
    return (
        <div className="content">
            <div className="top">
                <div className="column">
                    <ul>
                        <li> Rue du Pressoir 32 </li>
                        <li> 3960 Corin </li>
                        <a href="/cave/condition/" className='footer_condition'>
                             Mentions légales
                            </a>
                    </ul>
                
                </div>

                <div className="column">
                    <ul>
                        <li> Erwan Tapparel </li>
                        <li> 078 679 67 54 </li>
                        <li> vins@erwantapparel.ch </li>
                   
                    </ul>
                 
                </div>
                <div className="column">
                    <ul>
                        <li className="social_media">
                            <a href="/code-qr/"
                                className="qrcode social">
                                <img src={Code_image} className='qr-code_size' />
                            </a>

                            <a href="https://www.facebook.com/erwantapparel"
                                className="facebook social" target="_blank">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                            <a href="https://www.instagram.com/erwantapparel"
                                className="insta social" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                            <a href="https://www.youtube.com/@tapparelpiping"
                                className="youtube social" target="_blank">
                                <FontAwesomeIcon icon={faYoutube} size="2x" />
                            </a>
                          
                        </li>
                   
                      
                        <a href='https://www.webinblocks.ch/' className='footer_link_webinblocks'>&#9400; 2024 - Made with 💡 by WebInBlocks  </a>
                       

                    </ul>
                
                </div>

            </div>

        </div>
    )
}

export default footer