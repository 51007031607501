import { useEffect, useState } from "react";
import { listenToWineList } from "../GetServices";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from 'react-bootstrap/Button';
import { useCart } from '../../context/CartContext'; // Importez le contexte
import FilteredButtons from "../ui/FilteredButtons";
import { Popup } from "../ui/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import '../../styles/WineDisplay.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatedDiv } from "../ui/Animations";

function WineDisplay() {
  const [wineList, setWineList] = useState([]);
  const [selectedMillesime, setSelectedMillesime] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedWine, setSelectedWine] = useState(null);
  //gestion des quantités séléctionnées par l'utilisateur
  const [quantities, setQuantities] = useState({});
  const { addToCart } = useCart(); // Utilisez le contexte pour ajouter des articles au panier
  const { cart, setCart } = useCart([]);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  const togglePopup = (wine) => {
    setSelectedWine(wine)
    setShowPopup(!showPopup);
    document.body.classList.toggle('no-scroll', !showPopup);
  };
  const closePopup = () => {
    setShowPopup(false);
    document.body.classList.remove('no-scroll');
  };
  
  useEffect(() => {
    const unsubscribe = listenToWineList((data) => {
      setWineList(data);
    });
    //unsubscribe permet d'éviter les fuites de mémoire
    return () => unsubscribe();
  }, []);
  const filteredWines = selectedMillesime
    ? wineList
      .filter((wine) => wine.millesime === selectedMillesime)
      .sort((a, b) => a.index - b.index)
    : wineList.sort((a, b) => a.index - b.index);

  //pour chaque vin (wineId) on gère la mise à jour de la quantité
  const handleQuantityChange = (wineId, value) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [wineId]: Math.max(0, (0) + value)
    }));
  };

  const handleAddToCart = (wine) => {
    const quantityToAdd = quantities[wine.id] || 0;
    if (quantityToAdd > 0) {
      addToCart(wine, quantityToAdd);
      setShowNotification(true)
    }
  };
  const millesimeItems = [
    { label: 'Tous les vins', value: null },
    { label: 'Millésime 2022', value: 2022 },
    { label: 'Millésime 2023', value: 2023 }
  ];

  const getBackgroundClass = (description) => {

    description = description.toLowerCase();
    if (description.includes('barrique')) {
      return 'bg-barrel';
    } else if (description.includes('raisin') || description.toLowerCase().includes('cep')) {
      return 'bg-grappe';
    } else if (description.includes('elevage') || description.includes('cuve')) {
      return 'bg-bottle';
    } else if (description.includes('parcelle')) {
      return 'bg-parcelle';
    } else {
      return 'bg-wine';
    }
  };

  return (
    <div>
      
      <FilteredButtons items={millesimeItems} setSelected={setSelectedMillesime} selectedValue={selectedMillesime} />
   
      {filteredWines.map((wine) => (
           <AnimatedDiv delay={0.3}>
        <div key={wine.id} className="wine-container">
        
          <div className="wine_image_responsive">
            <img src={wine.image} alt={wine.variety} className="bottle" />
          </div>
          <div className="wine-details">
            <div className="title_size"> {wine.variety} {wine.millesime} - {wine.location} </div>
            
            <div className="wine-list-description1"> {wine.joke} </div>
            <ul className="wine-list-description2">
              <li className={getBackgroundClass(wine.description_1)}>{wine.description_1}</li>
              <li className={getBackgroundClass(wine.description_2)}>{wine.description_2}</li>
              <li className={getBackgroundClass(wine.description_3)}>{wine.description_3}</li>
            </ul>
            <p className="wine-list-description3"> {wine.alcohol_level} % - bouteille de {wine.bottle_size} cl </p>
            <p className="wine-list-description3"> CHF {wine.price} .- </p>
            <div className="btn_popup">
              <Button onClick={() => togglePopup(wine)} className="btn_informations">
                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '8px' }} />
                Fiche technique </Button>
                <div className="quantity-control">
            {wine.stock > 0 ? (
              <>
                <select className="select_quantity" onChange={(e) => handleQuantityChange(wine.id, parseInt(e.target.value))}>
                  {[...Array(25).keys()].map((num) => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
                <div className="btn_add" onClick={() => handleAddToCart(wine)}>
                  <ShoppingCartIcon />
                </div>
              </>
            ) : (
              <button className="btn_out_of_stock" disabled>Épuisé</button>
            )}
              
                  {showNotification && (
                    <div className={`notification ${showNotification ? 'show' : ''}`}>
                      <p className="addBasket_notif"> Vin ajouté au panier ! </p>
                      <p className="recapOrder_notif"> Récapitulatif de votre commande </p>
                      {cart.map((e, index) => (
                        <div className="item_notif" key={index}>
                       
                          <img src={e.image} alt={e.variety} className="bottle_notif" />
                          <div className="cart_item_notif">
                            <p className="text_notif"> {e.variety} {e.millesime} </p>
                            <p className="price_notif"> CHF {e.price * e.quantity} .- </p>
                            
                          </div>
                        </div>
                      ))}
                      <div className="notification-buttons">
                      <button onClick={() => setShowNotification(false)}>Continuer vos achats</button>
                      <button onClick={() =>  navigate('/cave/panier')}> Aller au panier</button>
                      <button className="close_btn" onClick={() => setShowNotification(false)}>×</button>
                      </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </AnimatedDiv>
      ))}
      <Popup show={showPopup} onClose={closePopup} wine={selectedWine} />
   
    </div>
  );
}

export default WineDisplay;